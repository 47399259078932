<template>
  <div class="single-sys-common single-sys-user">
    <div class="mb-2">
      <el-button class="btn-primary" @click="goto()">新增</el-button>
    </div>
    <div class="table-wrapper">
      <el-table
        :data="tableData"
        stripe
        height="calc(100% - 40px)"
        style="width: 100%"
        :header-cell-style="{ backgroundColor: '#E7F0FC' }"
        :header-row-style="{ color: '#3c3c3c' }"
        :row-style="{ color: '#5a5a5a', 'font-weight': 500 }"
      >
        <el-table-column
          prop="username"
          label="用户名"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="name"
          label="名称"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="isProvince"
          label="是否为省用户"
          :formatter="formatterData"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <span class="opt-edit mr-6" @click="goto(scope.row)">编辑</span>
            <span class="opt-delete" @click="pageDel(scope.row.id)">删除</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        layout="prev, pager, next"
        :total="pageParams.total"
        :page-size="pageParams.pageSize"
        :current-page="pageParams.pageNum"
        @current-change="currentChange"
      >
      </el-pagination>
    </div>
    <!-- 新增和编辑弹框 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="dialogVisible"
      :before-close="beforeDialogClose"
    >
      <div class="form-wrapper manage-scroll">
        <el-form
          :model="handleData"
          :rules="formRules"
          ref="ruleForm"
          label-width="120px"
          class="demo-ruleForm"
        >
          <el-row :gutter="20" class="w-full">
            <el-col :span="24">
              <el-form-item label="名称" prop="name">
                <el-input v-model="handleData.name" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="用户名" prop="username">
                <el-input v-model="handleData.username" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
              <el-col :span="24">
                <el-form-item label="密码" prop="password">
                  <el-input type="password" show-password v-model="handleData.password" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
            <el-col :span="24">
              <el-form-item label="是否为省用户" prop="isProvince">
                <el-radio-group v-model="handleData.isProvince">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="24" v-if="handleData.isProvince === 0">
              <el-form-item label="桥梁" prop="bridgeIdList">
                <el-select multiple collapse-tags v-model="handleData.bridgeIdList">
                  <el-option
                          v-for="item in bridgeList"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <div class="z-form-item-group">
                <el-form-item label="菜单权限" prop="menuIdList">
                  <el-tree show-checkbox
                           :data="menuTree"
                           :check-strictly="true"
                           :default-checked-keys="handleData.menuIdList"
                           node-key="id"
                           ref="tree"
                           highlight-current :props="dialog.props" class="z-form-item z-card z-tree">
                  </el-tree>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetFields">取 消</el-button>
        <el-button class="btn-primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pageParams: {
        total: 0,
        pageSize: 10,
        pageNum: 1,
      },
      bridgeList:[],
      tableData: [],
      dialogVisible: false,
      handleData: {
        bridgeIdList:[],
        menuIdList:[]
      },
      formRules: {
        name: [
          { required: true, message: '请填写名称', trigger: 'blur' }
        ],
        username: [
          { required: true, message: '请填写用户名', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请填写密码', trigger: 'blur' }
        ],
        bridgeIdList: [
          { required: true, message: '请选择桥梁', trigger: 'blur' }
        ],
        isProvince: [
          { required: true, message: '请选择是否为省用户', trigger: 'blur' }
        ],
      },
      title: "",
      dialogType: "",
      api:'',
      menuTree:[],
      dialog:{
        visible:false,
        menuIdList:[],
        props: {
          children: 'children',
          label: 'name'
        }
      },
    };
  },
  created() {
    this.initPage()
  },
  methods: {
    initPage(){
      this.$http.post('/bridge/sys/user/listPage',this.pageParams).then(res => {
        if (res.success){
          this.tableData = res.data.list
          this.pageParams.total = res.data.total
        }
      })
    },
    formatterData(row){
      return row.isProvince === 1 ? '是' : '否'
    },
    goto(data) {
      this.$http.get('/bridge/info/listAll').then(res => {
        if (res.success){
          this.getMenu()
          this.bridgeList = res.data
          this.dialogType = "normal";
          if (data) {
            this.title = "编辑";
            this.api = '/bridge/sys/user/update'
            this.getDetail(data.id)
          } else {
            this.handleData = {bridgeIdList:[],menuIdList:[]}
            this.title = "新增";
            this.api = '/bridge/sys/user/save'
            this.dialogVisible = true;
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    getMenu(){
      this.$http.get('/bridge/sys/menu/tree').then(res => {
        if (res.success){
          this.menuTree = res.data
          // this.dialog.menuIds = []
          // res.data.forEach(item => {
          //   if (item.checked === true && item.children.length === 0){
          //     this.dialog.menuIds.push(item.id)
          //   }
          //   if (item.children.length > 0){
          //     item.children.forEach(child => {
          //       if (child.checked === true){
          //         this.dialog.menuIds.push(child.id)
          //       }
          //     })
          //   }
          // })
          // this.dialog.visible = true
        }
      })
    },
    getDetail(id){
      this.$http.get('/bridge/sys/user/detail',{params:{id:id}}).then(res => {
        if (res.success){
          this.handleData = res.data
          this.dialogVisible = true;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    pageDel(id) {
      this.$http.get('/bridge/sys/user/remove',{params:{id:id}}).then(res => {
        if (res.success){
          this.initPage()
          this.$message.success('删除成功')
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    currentChange(current) {
      this.pageParams.pageNum = current;
      this.initPage()
    },
    beforeDialogClose(done) {
      if (this.dialogType == "normal") {
        this.$refs.ruleForm.resetFields();
      }
      done();
    },
    submit() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          if (this.handleData.isProvince === 1){
            this.handleData.bridgeIdList = []
            this.bridgeList.forEach( item => {
              this.handleData.bridgeIdList.push(item.id)
            })
          }
          this.handleData.menuIdList = this.$refs.tree.getCheckedKeys();
          console.log(this.handleData);
          this.$http.post(this.api,this.handleData).then(res => {
            if (res.success){
              this.initPage()
              this.$refs.tree.setCheckedKeys([])
              this.dialogVisible = false;
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    },
    resetFields() {
      this.dialogVisible = false;
      this.$refs.ruleForm.resetFields();
    },
  },
};
</script>
